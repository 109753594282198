import React from 'react'
import { graphql } from 'gatsby'

// Components
import Layout from '../layouts/index'
import Wrapper from '../components/wrapper'
import Seemore from '../components/seemore'

const NotFoundPage = ({ data, path }) => (
  <Layout path={path} locale={'en'}>
    <Wrapper color="yellow">
      <Wrapper grid>
        <div className="notfound">
          <h1 className="jumbo"
              dangerouslySetInnerHTML={{__html: data.datoCmsNotfound.headline}} />
            <div className="paragraph"
              dangerouslySetInnerHTML={{__html:data.datoCmsNotfound.paragraph}}  />
          <Seemore
            content={{
              textLink: data.datoCmsNotfound.textLink,
              link: { link: "/", locale: "en" }
            }}
            rotated
          />
        </div>
      </Wrapper>
    </Wrapper>
  </Layout>
)

export default NotFoundPage

export const query = graphql`
  query NotFoundENQuery {
    datoCmsNotfound(locale: {eq: "en"}) {
      headline
      paragraph
      textLink
      locale
    }
  }
`
